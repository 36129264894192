import './index.css'
import Display from './Components/Display.js'
import Topbar from './Components/Topbar.js'

function App() {
  return (
    <>
      <Topbar />
      <Display />
    </>
  );
}

export default App;
